<template>
  <div class="details-page">
    <div class="labs-page" style="height: 80vh">
      <div class="pt-6 px-4" style="height: 70vh">
        <loading-circle v-if="loading" />
        <Detail v-else :type="type" :src="src" />
      </div>

      <Notify
        v-if="notification.show"
        :width="containerWidth"
        :type="notification.type"
        no-title
        @close="notification.show = false"
        @consent="notification.show = false"
        :message="notification.message"
      />
    </div>

    <v-footer
      height="60px"
      style="
        background-color: transparent;
        margin-bottom: 5px;
        width: 100%;
        justify-content: center;
        position: absolute;
        bottom: 0px;
      "
    >
      <Btn
        :loading="downloading"
        theme="primary"
        width="90%"
        @click="getDocument"
      >
        Download
      </Btn>
    </v-footer>
  </div>
</template>
<script>
import { api } from '../../sharedPlugin';
import LoadingCircle from '@/components/LoadingCircle.vue';
import Notify from '@/components/global/Notify';
import Detail from '@/components/documents/Detail.vue';

export default {
  components: { LoadingCircle, Notify, Detail },
  data: () => ({
    categoryList: [],
    loading: false,
    downloading: false,
    dialog: false,
    file: null,
    category: 0,
    notification: {
      show: false,
      message: 'File type not supported',
      type: 'error'
    },
    documentName: null,
    fileName: null,
    documentId: null,
    src: null,
    type: null
  }),
  computed: {},
  methods: {
    async getDocument() {
      if (this.documentId == null) {
        return false;
      }
      this.downloading = true;
      api.Documents.getDocumentById(this.documentId)
        .then(async (result) => {
          this.fileName = this.evaluateFileExt(this.documentName);

          let arrayBuffer = this.base64ToArrayBuffer(result.document);
          if (result.s3dockey) {
            arrayBuffer = await api.Documents.getS3PDF(result.s3dockey).then(
              (res) => {
                return new Uint8Array(res.data.Body.data);
              }
            );
          }

          const blob = new Blob([arrayBuffer], {
            type: 'application/octet-stream'
          });

          var a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = this.fileName;
          a.click();
          this.downloading = false;
          setTimeout(() => {
            this.$store.dispatch('messages/pullNotificationUnreadCount', true);
          }, 200);
        })
        .finally(() => {
          this.$store.dispatch('messages/pullNotificationUnreadCount', true);
          this.downloading = false;
        });
    },
    evaluateFileExt(fileName) {
      var file = fileName.split('.');
      fileName = file[0];
      var fileExt = file[file.length - 1];
      if (fileExt === 'docx') fileExt = 'doc';
      fileName = fileName + '.' + fileExt;

      return fileName;
    },
    base64ToArrayBuffer(data) {
      const binaryString = atob(data);
      const bytes = new Uint8Array(binaryString.length);
      return bytes.map((byte, i) => binaryString.charCodeAt(i));
    },
    getFile(id) {
      try {
        this.loading = true;

        api.Documents.readDocument(id);

        api.Documents.getDocumentById(id)
          .then(async (result) => {
            if (result.name)
              this.$store.commit('setUpdateRouteName', result.name);

            var file = result.name.split('.');
            var fileType = file[file.length - 1];

            if (
              fileType === 'pdf' ||
              fileType === 'jpg' ||
              fileType === 'jpeg' ||
              fileType === 'png'
            ) {
              this.dialogPreview = true;
              this.documentName = result.name;
              this.documentId = id;
              this.type = null;
              this.src = null;
              this.categoriesLoading = true;
            } else {
              //this.snackbar = true;
              this.loading = false;
              this.notification.show = true;
              return;
            }
            if (result.document) {
              if (fileType === 'pdf') {
                this.type = 'doc';
                this.src = 'data:application/pdf;base64,' + result.document;
              } else if (fileType === 'jpg' || fileType === 'jpeg') {
                this.type = 'img';
                this.src = 'data:image/jpeg;base64,' + result.document;
              } else if (fileType === 'png') {
                this.type = 'img';
                this.src = 'data:image/png;base64,' + result.document;
              }
            } else {
              this.type = 'pdf';
              this.src =
                'data:application/pdf;base64,' +
                (await api.Documents.getS3PDF(result.s3dockey)
                  .then((res) => {
                    return Buffer.from(res.data.Body.data).toString('base64');
                  })
                  .catch((err) => err));
            }

            this.loading = false;

            setTimeout(() => {
              this.$store.dispatch(
                'messages/pullNotificationUnreadCount',
                true
              );
            }, 200);
          })
          .catch((err) => {
            this.src = null;

            this.loading = false;
          });
      } catch (error) {
        this.loading = false;
        this.src = null;
      }
    },
    goToLink(item) {
      this.$router.push(`/documents/list/${item}`);
    }
  },
  created() {
    this.getFile(this.$route.params.id, this.$route.params.name);
  }
};
</script>
<style lang="scss">
.details-page {
  height: 100%;
  .v-card__title {
    h1 {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #ffffff;
    }
  }
}
</style>
